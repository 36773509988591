/* FUENTES PROPIAS */

@font-face {
  font-family: 'helvetica';
  src: url('../fonts/helvetica-neue-5/HelveticaNeueRoman.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'pasti';
  src: url('../fonts/pasti/PastiRegular-mLXnm.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* POPPINS */
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
/* fin POPPINS */


@font-face {
  font-family: 'asd';
  src: url('../fonts/DisneyEncanto-JRALE.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



/* no me sirven */
@font-face {
  font-family: 'nolan';
  src: url('../fonts/nolan-next/nolanNextRegular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'lilita';
    src: url('../fonts/LilitaOne-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'oswaldRegular';
  src: url('../fonts/Oswald-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'oswaldBold';
  src: url('../fonts/Oswald-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'proximanovaRegular';
  src: url('../fonts/proximanova-regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proximanovaBold';
  src: url('../fonts/proximanova-bold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}